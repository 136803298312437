<template>
  <div class="admin-list">
    <PageTitle :title="$t('page.adminSetting.title')" :btn="$t('common.button.create.text')" @btnClick="openDialog('create')" />
    <section class="flex-end">
      <!-- <SearchInput type="single" placeholder="請輸入使用者ID" :data.sync="userSearch"/> -->
    </section>

    <section>
      <BaseTable v-loading="loading.table" :data="tableData" :empty-text="$t('common.table.empty.text')">
        <EmptyBlock slot="empty" />
        <BaseElTableColumn :label="$t('adminSetting.table.name.title')" prop="name" align="center" />
        <BaseElTableColumn
          :label="$t('adminSetting.table.email.title')"
          prop="AuthEmail.email"
          align="center"
        />
        <BaseElTableColumn :label="$t('adminSetting.table.role.title')" prop="role" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.role)">
              {{
                roleTrans(scope.row.role)
              }}
            </Tag>
          </template>
        </BaseElTableColumn>

        <!-- <BaseElTableColumn label="ID" prop="id" align="center"/> -->

        <BaseElTableColumn fixed="right" :label="$t('common.table.action.text')" width="100" align="center">
          <template slot-scope="user">
            <el-popover placement="top">
              <div v-if="selectRow" class="table-edit-btn-popover">
                <BaseElButton
                  type="text"
                  @click="
                    openDialog('update'),
                    (selectRow = user.row)
                  "
                >
                  {{ $t('adminSetting.editInfo.title') }}
                </BaseElButton>
                <BaseElButton
                  type="text"
                  @click="
                    openDialog('password'),
                    (selectRow = user.row)
                  "
                >
                  {{ $t('adminSetting.editPassword.title') }}
                </BaseElButton>
                <BaseElButton
                  v-if="!selectRow.roleAllShop && displayShopManageByRole(user.row.role)"
                  type="text"
                  @click="
                    openDialog('shop'),
                    (selectRow = user.row)
                  "
                >
                  {{ $t('adminSetting.editBranch.title') }}
                </BaseElButton>
                <BaseElButton
                  type="text"
                  class="delete"
                  @click=";(deleteDialog = true), (selectRow = user.row)"
                >
                  {{ $t('common.button.delete.text') }}
                </BaseElButton>
              </div>
              <TableSettingIconButton slot="reference" @click="selectRow = user.row" />
              <!-- <BaseElButton
                slot="reference"
                type="text"
                class="btn-type-edit-row"
                icon="el-icon-setting"
                @click="selectRow = user.row"
              /> -->
            </el-popover>
          </template>
        </BaseElTableColumn>
      </BaseTable>
      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="tableDataCount"
        @pageChange="refresh(false)"
      />
    </section>

    <!-- Dialog -->
    <!-- <el-dialog
      :title="dialogTitle"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      @close="resetForm()"
    >
      <BaseElForm
        ref="form"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <BaseElFormItem
          v-if="showInput(['create', 'update'])"
          :label="$t('adminSetting.dialog.name.title')"
          prop="name"
        >
          <BaseElInput v-model="formData.name" :placeholder="$t('adminSetting.dialog.name.placeholder')" />
        </BaseElFormItem>
        <BaseElFormItem
          v-if="showInput(['create'])"
          :label="$t('adminSetting.dialog.email.title')"
          prop="email"
        >
          <BaseElInput v-model="formData.email" :placeholder="$t('adminSetting.dialog.email.placeholder')" />
        </BaseElFormItem>
        <BaseElFormItem
          v-if="showInput(['create', 'password'])"
          :label="$t('adminSetting.dialog.password.title')"
          prop="password"
        >
          <BaseElInput v-model="formData.password" :placeholder="$t('adminSetting.dialog.password.placeholder')" />
        </BaseElFormItem>
        <BaseElFormItem
          v-if="showInput(['create', 'update'])"
          :label="$t('adminSetting.dialog.role.title')"
          prop="role"
        >
          <BaseElSelect
            v-model="formData.role"
            :disabled="disabledRoleSelect"
            :placeholder="$t('adminSetting.dialog.role.placeholder')"
          >
            <BaseElSelectOption
              v-for="item in availableRoleList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem
          v-if="showInput(['create', 'shop']) && formData.role !== 'orgAdmin' && showManageShop(formData.role)"
          :label="$t('adminSetting.dialog.branch.title')"
          prop="Shops"
        >
          <ShopMultiSelect :model.sync="formData.Shops" />
        </BaseElFormItem>
      </BaseElForm>

      <div slot="footer">
        <BaseElButton
          plain
          @click=";(showDialog = false), resetForm()"
        >
          {{ $t('common.button.cancel.text') }}
        </BaseElButton>
        <BaseElButton type="primary" @click="handleDialogConfirm">
          {{
            dialogType === 'create' ? $t('common.button.create.text') : $t('common.button.save.text')
          }}
        </BaseElButton>
      </div>
    </el-dialog> -->

    <EditAdminModal
      v-if="modal.edit"
      :action="dialogType"
      :selectRow="selectRow"
      @close="modal.edit = false"
      @refresh="refresh"
    />

    <DeleteDialog
      v-if="deleteDialog"
      :title="$t('common.dialog.delete.title')"
      :content="$t('common.dialog.delete.content')"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteUser(), (deleteDialog = false)"
    />
  </div>
</template>

<script>
import adminRole from '@/config/admin'

import {
  GetUser,
  CreateUser,
  DeleteUser,
  UpdateUser,
  UpdateUserPassword,
  UpdateUserShops,
  GetUsersCount,
  FindCustomRole,
} from '@/api/user'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { noEmptyRules, emailRules } from '@/validation'
import { extractList } from '@/utils/helper'
import { i18n } from '@/plugins/i18n/i18n'
import EditAdminModal from './components/EditAdminModal.vue'
import { computed, defineComponent, onMounted, provide, reactive, ref } from 'vue'
import store from '@/store'
import { useTable } from '@/use/table'
import { apiFormatAdaptor } from '@/utils/api'

export default defineComponent({
  name: 'AdminList',
  components: { DeleteDialog, EditAdminModal, EmptyBlock },
  setup () {
    const selectRow = ref(null)
    const modal = reactive({
      edit: false,
    })
    const userSearch = ref('')
    const {
      loading,
      tableData,
      tableOptions,
      tableDataCount,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
    } = useTable()
    const adminRoleList = ['orgAdmin', 'shopManager', 'shopProvider', 'shopMod', 'shopOperation']
    const userList = ref([])
    const usersCount = ref(0)
    const customRoleOptions = ref([])
    const showDialog = ref(false)
    const dialogType = ref('create')
    const deleteDialog = ref(false)

    const getTableData = async () => {
      const payload = {
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        // name: search.name || undefined,
        // role: search.role || undefined,
      }
      await Promise.allSettled([
        fetchData(apiFormatAdaptor(GetUser), payload),
        fetchDataCount(apiFormatAdaptor(GetUsersCount), payload),
      ])
    }

    const formRules = reactive({
      name: noEmptyRules(),
      email: emailRules(),
      password: [
        { min: 6, message: i18n.t('validation.minLength.text', { number: '6' }), trigger: 'blur' },
        noEmptyRules(),
      ],
      role: noEmptyRules(),
    })

    const dialogTitle = computed(() => dialogTitle(dialogType.value, {
      create: i18n.t('adminSetting.createAdmin.title'),
      update: i18n.t('adminSetting.editInfo.title'),
      password: i18n.t('adminSetting.editPassword.title'),
      shop: i18n.t('adminSetting.editBranch.title'),
    }))

    const curUser = computed(() => store.getters.user)

    const disabledRoleSelect = computed(() => {
      if (!selectRow.value) return false
      return selectRow.value.id === curUser.value.id
    })

    const availableRoleList = computed(() => {
      const availableRoleList = []
      adminRoleList.forEach(item => {
        availableRoleList.push(adminRole[item])
      })
      customRoleOptions.value.map(item => {
        availableRoleList.push({ name: item.name, value: item.role })
      })
      return availableRoleList
    })

    const roleTrans = (role) => {
      const targetRole = availableRoleList.value.find(item => item.value === role)
      return targetRole ? targetRole.name : ''
    }

    const tagType = (val) => {
      const type = {
        orgAdmin: 'warning',
        shopManager: 'action',
        shopProvider: 'info',
        shopMod: 'action',
      }
      return type[val] || 'info'
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      await getUserCustomRole()
      loading.table = false
    }

    const openDialog = async (type) => {
      dialogType.value = type
      showDialog.value = true
      modal.edit = true
    }

    const showManageShop = (role) => {
      const target = customRoleOptions.value.find(item => item.role === role)
      return target ? target.needSettingShops : true
    }

    const deleteUser = async () => {
      try {
        await DeleteUser({ id: selectRow.value.id })
        selectRow.value = null
        await refresh()
        window.$message.success(i18n.t('common.message.deleteSuccess'))
      } catch (error) {
        console.log(error)
        window.$message.error({
          message: error || error.message,
        })
      }
    }

    const getUserCustomRole = async () => {
      try {
        customRoleOptions.value = await FindCustomRole()
      } catch (error) {
        console.log(error)
        window.$message(error)
      }
    }

    const displayShopManageByRole = (role) => {
      const targetRole = customRoleOptions.value.find(item => item.role === role)
      return targetRole ? targetRole.needSettingShops : true
    }

    provide('availableRoleList', availableRoleList)
    provide('customRoleOptions', customRoleOptions)

    onMounted(async () => {
      await refresh()
    })

    return {
      selectRow,
      modal,
      loading,
      userSearch,
      tableOptions,
      adminRoleList,
      userList,
      usersCount,
      showDialog,
      dialogType,
      deleteDialog,
      formRules,
      pageStartIndex,
      dialogTitle,
      curUser,
      disabledRoleSelect,
      roleTrans,
      tagType,
      refresh,
      openDialog,
      showManageShop,
      deleteUser,
      getUserCustomRole,
      displayShopManageByRole,
      tableData,
      tableDataCount,
    }
  },
})
</script>

<style scoped lang="scss"></style>
